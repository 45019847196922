<template>
  <div>
    <div v-loading="loading">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="40%"
      >
        <el-form
          label-position="top"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="form"
          v-if="model"
          v-loading="loading"
        >
          <el-form-item
            :label="fields.vorlageDienstgeber.label"
            :prop="fields.vorlageDienstgeber.name"
            :required="fields.vorlageDienstgeber.required"
          >
            <app-vorlage-dienstgeber-autocomplete-input
              :fetchFn="fields.vorlageDienstgeber.fetchFn"
              :mapperFn="fields.vorlageDienstgeber.mapperFn"
              mode="single"
              filter="arbeitsbestaetigung"
              v-model="model[fields.vorlageDienstgeber.name]"
            ></app-vorlage-dienstgeber-autocomplete-input>
          </el-form-item>

          <el-form-item>
            <div class="form-buttons">
              <el-button
                :disabled="loading"
                @click="doSubmit"
                icon="el-icon-fa-floppy-o"
                type="primary"
              >
                <app-i18n code="common.prepare"></app-i18n>
              </el-button>

              <el-button
                :disabled="loading"
                @click="doCancel"
                icon="el-icon-fa-close"
              >
                <app-i18n code="common.cancel"></app-i18n>
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BestaetigungModel } from '@/modules/bestaetigung/bestaetigung-model';
import { FormSchema } from '@/shared/form/form-schema';
import { i18n } from '@/i18n';

const { fields } = BestaetigungModel;
const formSchema = new FormSchema([fields.vorlageDienstgeber]);

export default {
  name: 'app-bestaetigung-form-modal',

  props: ['visible', 'loading'],

  data() {
    return {
      model: null,
      rules: formSchema.rules(),
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      mitarbeiterId: 'mitarbeiter/form/id',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },
    title() {
      return i18n('entities.bestaetigung.name');
    },
  },
  methods: {
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);
      values.mitarbeiter = this.mitarbeiterId;
      return this.$emit('submit', {
        id,
        values,
      });
    },
    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
