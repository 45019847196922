<template>
  <div>
    <div>
      <app-bestaetigung-entwurf-modal
        :visible="dialogEntwurfVisible"
        v-on:close="closeEntwurfDialog"
        v-if="dialogEntwurfVisible"
        :arrayBuffer="file"
        :fullName="fullName"
      ></app-bestaetigung-entwurf-modal>
      <app-bestaetigung-form-modal
        :visible="dialogFormVisible"
        v-on:close="doCloseFormModal"
        v-on:submit="doSubmit"
        v-if="dialogFormVisible"
        :loading="loading"
        ref="formModal"
      ></app-bestaetigung-form-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BestaetigungEntwurfModal from '@/modules/bestaetigung/components/bestaetigung-entwurf-modal.vue';
import BestaetigungFormModal from '@/modules/bestaetigung/components/bestaetigung-form-modal.vue';

export default {
  name: 'app-bestaetigung-portal',
  props: ['id', 'visible', 'fullName'],
  components: {
    [BestaetigungEntwurfModal.name]: BestaetigungEntwurfModal,
    [BestaetigungFormModal.name]: BestaetigungFormModal,
  },
  data() {
    return {
      dialogEntwurfVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'bestaetigung/loading',
      file: 'bestaetigung/file',
    }),
    dialogFormVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({
      doCreate: 'bestaetigung/doCreate',
      doReset: 'bestaetigung/doReset',
    }),
    async doSubmit(payload) {
      await this.doCreate(payload.values);
      this.openEntwurfDialog();
    },
    doOpenFormModal() {
      this.dialogFormVisible = true;
    },
    doCloseFormModal() {
      this.dialogFormVisible = false;
    },
    openEntwurfDialog() {
      this.dialogEntwurfVisible = true;
    },
    closeEntwurfDialog() {
      this.dialogEntwurfVisible = false;
      this.doReset();
    },
  },
};
</script>

<style></style>
