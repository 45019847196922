import { i18n } from '@/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import { VorlageDienstgeberField } from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-field';

function label(name) {
  return i18n(`entities.bestaetigung.fields.${name}`);
}

const fields = {
  vorlageDienstgeber: new VorlageDienstgeberField.relationToOne(
    'vorlageDienstgeber',
    label('vorlageDienstgeber'),
    {
      required: true,
    },
  ),
};

export class BestaetigungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
