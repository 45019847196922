<template>
  <div>
    <el-form
      label-position="top"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item label="Austrittsdatum" prop="austrittsdatum">
        <el-date-picker
          :format="elementUiDateFormat"
          placeholder
          type="date"
          v-model="model.austrittsdatum"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'app-abmeldung-form',

  props: ['saveLoading', 'modal'],

  data() {
    return {
      rules: {
        austrittsdatum: [
          {
            type: 'date',
            required: true,
            message: 'Bitte wählen Sie ein Datum',
            trigger: 'change',
          },
        ],
      },
      model: {
        austrittsdatum: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      const austrittsdatum = moment(this.model.austrittsdatum)
        .utcOffset(0, true)
        .format('YYYY-MM-DD');
      return this.$emit('submit', { austrittsdatum });
    },
  },
};
</script>

<style></style>
