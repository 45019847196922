<template>
  <div>
    <div v-if="!isBlank">
      <el-timeline :reverse="true">
        <el-timeline-item
          v-for="(note, index) in notiz"
          :key="index"
          :timestamp="note.timestamp"
        >
          {{ note.note }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <div v-else>
      <el-alert
        title="Note"
        description="No notiz posted."
        type="info"
        :closable="false"
        show-icon
      >
      </el-alert>
    </div>
    <div>
      <el-input
        placeholder="Bitte wichtigen Hinweis eingeben"
        type="textarea"
        v-model="noteTxtArea"
        :autosize="{ minRows: 5, maxRows: 5 }"
        class="noteTextArea"
        maxlength="500"
        show-word-limit
        resize="none"
      ></el-input>
      <el-button @click="doSubmit" type="primary" class="noteButton">
        Submit
      </el-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'app-mitarbeiter-notiz',

  props: ['records', 'mitarbeiter'],

  data() {
    return {
      noteTxtArea: '',
    };
  },

  computed: {
    isBlank() {
      return !this.records || !this.records.length;
    },

    notiz() {
      return this.records.map((record) => {
        return {
          note: record.note,
          timestamp: `${record.createdBy} at ${moment(
            record.createdAt,
          ).format('DD.MM.YYYY HH:mm')} `,
        };
      });
    },
  },

  methods: {
    doSubmit() {
      const note = {
        note: this.noteTxtArea,
        mitarbeiter: this.mitarbeiter,
      };

      this.noteTxtArea = '';
      return this.$emit('doCreateNotiz', note);
    },
  },
};
</script>

<style scoped>
.el-timeline {
  max-height: calc(100% - 300px);
  max-width: 95%;
  width: 90%;
  overflow: auto;
  display: block;
  position: absolute;
  margin-left: -20px;
}

.noteButton {
  position: absolute;
  bottom: 10px;
  right: 20px;
  float: right;
}

.noteTextArea {
  padding: 20px;
  position: absolute;
  bottom: 50px;
  max-height: 20%;
  max-width: 90%;
}
</style>
