<template>
  <div class="app-pdf-view">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="0"
      width="60%"
      destroy-on-close
    >
      <el-row :gutter="10">
        <el-col :span="20" :offset="0">
          <div v-loading="loading">
            <app-pdf-view
              v-model="loading"
              :arrayBuffer="arrayBuffer"
            ></app-pdf-view>
          </div>
        </el-col>
        <div>
          <el-col :span="4" :offset="0">
            <el-card class="box-card actions" shadow="never">
              <div slot="header" class="clearfix">
                <span>Aktionen</span>
              </div>
              <div class="clearfix">
                <el-button v-on:click="doDownload()" type="text">
                  <app-i18n code="common.download"></app-i18n>
                </el-button>
              </div>
              <div class="clearfix">
                <el-button v-on:click="dialogVisible = false" type="text">
                  <app-i18n code="common.cancel"></app-i18n>
                </el-button>
              </div>
            </el-card>
          </el-col>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
/* import Errors from '@/shared/error/errors'; */
import { mapGetters } from 'vuex';
import FileSaver from 'file-saver';

export default {
  name: 'app-bestaetigung-entwurf-modal',

  props: ['arrayBuffer', 'visible', 'fullName'],

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    title() {
      return 'Preview'; //i18n('user.new.titleModal');
    },
  },

  methods: {
    doDownload() {
      const data = new Blob([this.arrayBuffer], {
        type: 'application/pdf',
      });
      FileSaver.saveAs(data, `Bestätigung_${this.fullName}.pdf`);
    },
  },
};
</script>

<style scoped>
.text-area {
  padding-top: 20px;
}
</style>
